@import "~bootstrap-icons/font/bootstrap-icons.css";

h1 {
  font-size: 2em !important;
}

h2 {
  font-size: 1.5em !important;
}

.multiselect-dropdown .dropdown-btn {
  border-color: #ced4da !important;
  padding: .25rem 1.5rem .25rem .5rem !important;
  font-size: .875rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  line-height: 1.5 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  font-size: .750rem !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  width: 30px !important;
  height: 35px !important;
}
